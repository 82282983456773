import React, {
  useEffect,
  ReactElement,
  useContext,
  useState,
  useRef,
} from "react";
import { DataContext } from "../../context/DataContext";
import { User, WorkspaceProvider } from "../../types";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import "./UserProfile.scss";
import Avatar from "./Avatar";
import { AuthContext } from "../../context/AuthContext";
import Popover from "@screencloud/screencloud-ui-components/.dist/components/popover";

interface UserProfileProps {
  userId: string;
  name: string;
}
const UserProfile = (
  props: UserProfileProps
): ReactElement<UserProfileProps> => {
  const { userId, name } = props;
  const { getSlackUser } = useContext(DataContext);
  const { currentWorkspace } = useContext(AuthContext);
  const [slackUser, setSlackUser] = useState((null as unknown) as User | null);
  const [isOpen, setIsOpen] = useState(false);
  const cardRef = useRef(null);
  useOnClickOutside(cardRef, () => setIsOpen(false));

  useEffect(() => {
    getSlackUser(userId).then((user) => setSlackUser(user));
  }, [userId, getSlackUser]);

  if (
    !currentWorkspace ||
    currentWorkspace.provider !== WorkspaceProvider.Slack
  ) {
    return (
      <span className="user-mention" style={{ cursor: "default" }}>
        {name}
      </span>
    );
  }

  return (
    <Popover
      className="user-profile-popover"
      position="top center"
      open={isOpen}
      trigger={
        <span
          className="user-mention"
          onClick={() => {
            setIsOpen(true);
          }}
        >
          {name}
        </span>
      }
    >
      {slackUser ? (
        <div className="user-profile-card" ref={cardRef}>
          <Avatar
            className="profile-picture"
            url={slackUser.profilePictureUrl}
          />
          <div className="user-detail">
            <span className="user-name">{slackUser.name}</span>
          </div>
        </div>
      ) : (
        <></>
      )}
    </Popover>
  );
};

export default UserProfile;
