import React, { ReactElement, ReactNode, useContext } from "react";
import { Message } from "../../types";
import moment from "moment";
import "./MessageHeader.scss";
import { Icon } from "semantic-ui-react";
import { DataContext } from "../../context/DataContext";
import Avatar from "../shared/Avatar";

interface MessageHeaderProps {
  message: Message | null;
  messageActions: ReactNode;
  onRemoveMessage?: () => void;
}

const MessageHeader = (
  props: MessageHeaderProps
): ReactElement<MessageHeaderProps> => {
  const { message, onRemoveMessage, messageActions } = props;
  const { currentUser } = useContext(DataContext);
  const title =
    message && message.title
      ? message.title
      : message
      ? message.text.slice(0, 60).replace(/\n/g, " ") + "..."
      : "";
  if (!message) {
    return <></>;
  }
  return (
    <div className="message-header">
      <div>
        <div className="message-detail-title">
          {message.permalink ? (
            <a
              href={message.permalink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {title}
            </a>
          ) : (
            <>{title}</>
          )}
          {currentUser &&
            currentUser.id === message.creator.id &&
            onRemoveMessage && (
              <Icon
                name="close"
                className="remove-message"
                size="small"
                title="Remove Message"
                onClick={onRemoveMessage}
              />
            )}
        </div>
        <div className="message-subtitle">
          <Avatar
            className="profile-picture"
            url={message.author.profilePictureUrl}
          />

          <span className="message-header-item">{`${message.author.name} /\u00A0`}</span>
          {message.channel && (
            <span className="message-header-item channel-name">{`#${message.channel.name} /\u00A0`}</span>
          )}
          <span className="message-header-item">
            {moment(new Date(Number(message.createdAt))).fromNow()}
          </span>
        </div>
      </div>
      {messageActions}
    </div>
  );
};

export default MessageHeader;
