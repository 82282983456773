import React, { ReactElement, ReactNode } from "react";
import "./MessageViewer.scss";
import { Message } from "../../types";
import MessageDetail from "../messages/MessageDetail";

interface MessageViewerProps {
  messages: (Message | null)[];
  onMessageRemoved?: (message: Message) => void;
  messageActions: ((message: Message | null) => ReactNode) | null;
}

const MessageViewer = (
  props: MessageViewerProps
): ReactElement<MessageViewerProps> => {
  const { messages, messageActions, onMessageRemoved } = props;

  return (
    <div className="message-viewer-container">
      {messages.length ? (
        messages.map((message, index) => {
          return (
            <MessageDetail
              key={index}
              message={message}
              messageActions={messageActions ? messageActions(message) : null}
              onMessageRemoved={onMessageRemoved}
            />
          );
        })
      ) : (
        <div className="no-messages-message">
          There are currently no messages in this channel.
          <br />
          Please pick another channel to browse from the Channels menu.
        </div>
      )}
    </div>
  );
};

export default MessageViewer;
