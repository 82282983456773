export const getCurrentUserQuery = `
  {
    currentUser {
      id
      name
      email
      workspace
      profilePictureUrl
      isAdmin
    }
  }`;
