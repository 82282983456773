import React, { ReactElement } from "react";
import Nav from "./Nav";
import PageNotFoundImage from "../icons/error.svg";
import "./PageNotFound.scss";

const PageNotFound = (): ReactElement<{}> => {
  return (
    <div className="page-not-found-container">
      <div className="left-panel">
        <Nav currentLocation="" title="" subtitle="" />
      </div>
      <div className="right-panel">
        <img src={PageNotFoundImage} alt="Page Not Found" />
        <div className="error-message">Something went wrong!</div>
        <div className="error-subtitle">
          Oops! The requested page was not found.
          <br />
          Please check the URL or select a page from the menu on the left.
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
