import React, { ReactElement } from "react";
import "./Login.scss";
import { WorkspaceProvider } from "../../types";
import signalImage from "./signal.png";

const Login = (): ReactElement<{}> => {
  const redirectUrl = new URLSearchParams(window.location.search).get(
    "redirectUrl"
  );
  const slackAuthUrl = `${process.env.REACT_APP_SLACK_AUTH_URI}?scope=${
    process.env.REACT_APP_SLACK_SCOPES
  }&client_id=${process.env.REACT_APP_SLACK_CLIENT_ID}&redirect_uri=${
    process.env.REACT_APP_REDIRECT_URI
  }&state=${JSON.stringify({
    redirectUrl,
    provider: WorkspaceProvider.Slack,
  })}`;
  const teamsAuthUrl = `${process.env.REACT_APP_TEAMS_AUTH_URI}?client_id=${
    process.env.REACT_APP_TEAMS_CLIENT_ID
  }&response_type=code&state=${JSON.stringify({
    redirectUrl,
    provider: WorkspaceProvider.Teams,
  })}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&prompt=select_account`;

  return (
    <div className="login-container">
      <div className="top-nav">
        <img alt="logo" className="logo" src="logo.svg" />
        <div className="links">
          <a
            href="https://get.screen.cloud/signal"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more
          </a>
          <a
            href="https://docs.google.com/document/d/1188ZhybDnnxg6eJ4qtzXnax_6ZL66KxndJTOA7lqMrk/edit"
            target="_blank"
            rel="noopener noreferrer"
          >
            Data
          </a>
        </div>
      </div>
      <div
        className="signal-image"
        style={{ backgroundImage: `url('${signalImage}')` }}
      />
      <div className="copy">
        <h1>Can’t find important updates in all the&nbsp;noise?</h1>
        <p>
          Signal collects what’s important from Slack and creates a personalized
          queue to help you catch up. Only the updates that matter to you and
          your role, minus all the&nbsp;noise.
        </p>
        <p className="mobile-support">Signal is not supported on mobile yet.</p>
        <div className="actions">
          <a href={slackAuthUrl} className="sign-in-button">
            <img alt="Sign in with Slack" src="slack.png" />
          </a>
          {process.env.REACT_APP_TEAMS_LOGIN_ENABLED === "true" ? (
            <a href={teamsAuthUrl} className="sign-in-button">
              <img alt="Sign in with Microsoft" src="teams.png" />
            </a>
          ) : (
            <a href={teamsAuthUrl} className="sign-in-button disabled">
              <img alt="Sign in with Microsoft" src="teams-coming-soon.png" />
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
