import React, { ReactElement, useRef } from "react";
import "./pattern.css";
import "./TextDetail.scss";
import { Message } from "../../types";
import Prettify from "../shared/Prettify";
import classNames from "classnames";

interface TextDetailProps {
  message: Message;
}

const backgroundPatterns = [
  "pattern-dots-sm",
  "pattern-dots-md",
  "pattern-diagonal-lines-sm",
  "pattern-horizontal-lines-sm",
];

const TextDetail = (props: TextDetailProps): ReactElement<TextDetailProps> => {
  const { message } = props;
  const pattern = useRef(Math.floor(Math.random() * backgroundPatterns.length));
  return (
    <>
      <div
        className={classNames("text-background", {
          "no-gradient": message.text.length < 400,
        })}
      >
        <div className="text-message">
          <div
            className={classNames("inner-container", {
              short: message.text.length < 400,
            })}
          >
            <Prettify text={message.text} />
          </div>
          {message.text.length < 400 && (
            <div
              className={`background ${backgroundPatterns[pattern.current]}`}
            ></div>
          )}
        </div>
      </div>
    </>
  );
};

export default TextDetail;
