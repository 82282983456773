import React, { ReactElement } from "react";
import "./GoogleSlidesDetail.scss";
import { Message } from "../../types";

interface GoogleSlidesDetailProps {
  message: Message;
}

const GoogleSlidesDetail = (
  props: GoogleSlidesDetailProps
): ReactElement<GoogleSlidesDetailProps> => {
  const { message } = props;
  return (
    <div className="slides-message">
      <iframe
        allowFullScreen
        id={message.id}
        className="slides-iframe"
        src={message.url}
        title={`Google Slides ${message.id}`}
      />
    </div>
  );
};

export default GoogleSlidesDetail;
