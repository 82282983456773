import { MessageCategory, categoryMap } from "../../../types";

export const getMessagesQuery = (category: MessageCategory) => `{
      allUnreadMessages(filter: ${categoryMap[category]}) {
        id
        title
        text
        permalink
        url
        author {
          name
          profilePictureUrl
        }
        creator {
          id
          name
        }
        channel {
          id
          name
          description
          topic
        }
        type
        provider
        readAt
        createdAt
      }
    }`;
