import React, {
  ReactElement,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import Loader from "@screencloud/screencloud-ui-components/.dist/components/loader";
import Button from "@screencloud/screencloud-ui-components/.dist/components/button";
import "./Catchup.scss";
import MessagePlayer from "../../components/viewers/MessagePlayer";
import Nav from "../../components/shared/Nav";
import {
  MessageCategory,
  Message,
  MessageCounts,
  Categories,
  CategoryMapping,
} from "../../types";
import { DataContext } from "../../context/DataContext";
import InboxZero from "../../icons/inbox-zero.svg";
import { Link } from "react-router-dom";
import CategoryList from "../../components/shared/CategoryList";
import TopBar from "../../components/shared/TopBar";
import ProgressBar from "../../components/shared/ProgressBar";
import { AuthContext } from "../../context/AuthContext";

const categories = [
  MessageCategory.MustSee,
  MessageCategory.Subscription,
  MessageCategory.Personal,
];

const Catchup = (): ReactElement<{}> => {
  const {
    getMessages,
    getUnreadMessageCounts,
    markMessageAsRead,
    removeMessage,
    reactToMessage,
    graphqlError,
  } = useContext(DataContext);
  const { currentWorkspace } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const [messageCounts, setMessageCounts] = useState<MessageCounts | null>(
    null
  );
  const [
    currentCategory,
    setCurrentCategory,
  ] = useState<MessageCategory | null>(null);

  const getMessageCount = useCallback(
    (category: MessageCategory): number => {
      if (messageCounts) {
        // @ts-ignore
        return messageCounts[
          (Categories.find((c) => c.type === category) as CategoryMapping).key
        ];
      }
      return 0;
    },
    [messageCounts]
  );

  useEffect(() => {
    getUnreadMessageCounts()
      .then((counts: MessageCounts) => {
        setMessageCounts(counts);
        if (!counts.mustSee) {
          if (counts.following) {
            setCurrentCategory(MessageCategory.Subscription);
          } else {
            setCurrentCategory(MessageCategory.Personal);
          }
        } else {
          setCurrentCategory(MessageCategory.MustSee);
        }
      })
      .catch(() => {
        setIsError(true);
        setIsLoading(false);
      });
  }, [getUnreadMessageCounts]);

  useEffect(() => {
    if (currentCategory) {
      setIsLoading(true);
      getMessages(currentCategory as MessageCategory).then((messages) => {
        setMessages(messages);
        setIsLoading(false);
        if (!messages.length) {
          const currentIndex = categories.indexOf(
            currentCategory as MessageCategory
          );
          const nextIndex = currentIndex + 1;
          if (nextIndex < categories.length) {
            setCurrentCategory(categories[nextIndex]);
          } else {
            setCurrentCategory(null);
          }
        }
      });
    }
  }, [currentCategory, getMessages]);

  useEffect(() => {
    if (graphqlError) {
      setIsError(true);
    }
  }, [graphqlError]);

  const markAsRead = (message: Message): void => {
    markMessageAsRead(message.id)
      .then(getUnreadMessageCounts)
      .then((counts) => {
        setMessageCounts(counts);
      });
  };

  const addReaction = (message: Message, reaction: string): void => {
    reactToMessage(message.id, reaction);
  };

  const onMessageRemoved = (message: Message): void => {
    removeMessage(message.id)
      .then(getUnreadMessageCounts)
      .then((counts) => {
        setMessageCounts(counts);
      })
      .then(() => {
        setIsLoading(true);
        return getMessages(currentCategory as MessageCategory);
      })
      .then((messages) => {
        setMessages(messages);
        setIsLoading(false);
      })
      .catch(() => {
        setIsError(true);
        setIsLoading(false);
      });
  };

  return (
    <div className="catchup-container">
      <div className="left-panel">
        <Nav
          currentLocation="/"
          title="Catch up"
          subtitle="Stay up to date with all your work news."
        >
          <CategoryList
            messageCounts={messageCounts}
            selectedCategory={currentCategory}
            onSelect={setCurrentCategory}
            showCheckMark
          />
        </Nav>
      </div>
      <div className="right-panel">
        {currentCategory && (
          <>
            <ProgressBar
              total={messages.length}
              current={messages.length - getMessageCount(currentCategory)}
            />
            <TopBar
              title={
                (Categories.find(
                  (c) => c.type === currentCategory
                ) as CategoryMapping).name
              }
              subtitle={
                (Categories.find(
                  (c) => c.type === currentCategory
                ) as CategoryMapping).description
              }
            >
              {!!messages.length &&
                getMessageCount(currentCategory) &&
                `${getMessageCount(currentCategory)} / ${
                  messages.length
                } messages left`}
            </TopBar>
          </>
        )}
        {isError ? (
          <div className="loading-message">
            There was an error while trying to load messages. Please refresh and
            try again.
          </div>
        ) : isLoading ? (
          <div className="loading-message">
            <Loader active size="massive" className="signal-loader" />
            <span>Loading messages...</span>
          </div>
        ) : messages.length && !!currentCategory ? (
          <MessagePlayer
            messages={messages}
            workspaceProvider={
              currentWorkspace ? currentWorkspace.provider : null
            }
            onMessageRead={markAsRead}
            onMessageReaction={addReaction}
            onNextChannel={() => {
              const currentIndex = categories.indexOf(
                currentCategory as MessageCategory
              );
              const nextIndex = currentIndex + 1;
              if (nextIndex < categories.length) {
                setCurrentCategory(categories[nextIndex]);
              } else {
                setCurrentCategory(null);
              }
            }}
            onMessageRemoved={onMessageRemoved}
          />
        ) : (
          <div className="caught-up-message">
            <img src={InboxZero} alt="Inbox Zero" />
            <h1>You{"’"}re caught up with all your important updates!</h1>
            <div className="controls">
              <Button outline>
                <Link to="/browse">Browse other channels</Link>
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Catchup;
