export const getMessagesForChannelQuery = (channelId: string) => `{
      allChannelMessages(id: "${channelId}") {
        id
        title
        permalink
        text
        url
        author {
          name
          profilePictureUrl
        }
        creator {
          id
          name
        }
        channel {
          id
          name
          description
          topic
        }
        type
        provider
        readAt
        createdAt
      }
    }`;
