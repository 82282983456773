import { useEffect, useState } from "react";
import useEventListener from "./useEventListener";

const ACTIVE_TIMEOUT = 5000;

const useShowWhenActive = (ref, precondition) => {
  const [isActive, setIsActive] = useState(true);
  const onMouseMove = () => {
    setIsActive(true);
  };
  useEventListener("mousemove", onMouseMove);

  useEffect(() => {
    if (isActive && precondition) {
      const timeout = setTimeout(() => {
        setIsActive(!precondition);
        clearTimeout(timeout);
      }, ACTIVE_TIMEOUT);
    }
  }, [isActive, precondition]);

  useEffect(() => {
    if (ref.current) {
      if (isActive) {
        if (precondition) {
          ref.current.style.transition = "all 0.5s";
        } else {
          ref.current.style.transition = "none";
        }
        ref.current.style.opacity = 1;
      } else {
        ref.current.style.transition = "all 0.5s";
        ref.current.style.opacity = 0;
      }
    }
  }, [isActive, ref, precondition]);
};

export default useShowWhenActive;
