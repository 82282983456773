export interface Message {
  id: string;
  type: MessageType;
  category: MessageCategory;
  title?: string;
  createdAt: string;
  channel: Channel;
  author: {
    name: string;
    profilePictureUrl: string;
  };
  creator: {
    name: string;
    id: string;
  };
  text: string;
  url?: string;
  permalink?: string;
  status: MessageStatus;
}

export enum MessageStatus {
  Seen = "SEEN",
  Unseen = "UNSEEN",
}

export enum VideoProvider {
  Youtube = "YouTube",
  Loom = "Loom",
  Zoom = "Zoom",
  Slack = "Slack",
  Vimeo = "Vimeo",
  Wistia = "Wistia, Inc.",
}

export interface VideoMessage extends Message {
  provider: VideoProvider;
}

export enum MessageCategory {
  MustSee = "MustSee",
  Subscription = "Subscription",
  Personal = "Personal",
}

export enum MessageType {
  Text = "TEXT",
  Photo = "IMAGE",
  Video = "VIDEO",
  Link = "LINK",
  GoogleSlides = "GOOGLESLIDES",
}

export enum ChannelProvider {
  Slack = "SLACK",
  Teams = "TEAMS",
}

export enum WorkspaceProvider {
  Slack = "slack",
  Teams = "teams",
}

export interface Workspace {
  id: string;
  name: string;
  provider: WorkspaceProvider;
}

export interface Channel {
  id: string;
  name: string;
  description: string;
  topic: string;
}

export interface ChannelSubscription {
  createdAt: string;
  channel: Channel;
}

export interface MessageCounts {
  saved: number;
  following: number;
  mustSee: number;
}

export interface User {
  name: string;
  id: string;
  email: string;
  workspace: string;
  profilePictureUrl: string;
  isAdmin: boolean;
}

export interface CategoryMapping {
  type: MessageCategory;
  name: string;
  description: string;
  key: string;
}
export const Categories: CategoryMapping[] = [
  {
    type: MessageCategory.MustSee,
    name: "Must See",
    description: "Company-wide announcements",
    key: "mustSee",
  },
  {
    type: MessageCategory.Subscription,
    name: "Following",
    description: "Updates from channels you follow",
    key: "following",
  },
  {
    type: MessageCategory.Personal,
    name: "Saved",
    description: "Your personal queue",
    key: "saved",
  },
];

export interface SessionInfo {
  token: string;
  expiresAt: number;
  refreshToken: string;
}

export const categoryMap: { [key: string]: string } = {
  Personal: "SAVED",
  Subscription: "FOLLOWING",
  MustSee: "MUSTSEE",
};
