import React, {
  ReactElement,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { findDOMNode } from "react-dom";
import ReactPlayer from "react-player";
import screenfull from "screenfull";
import ScreenCloudIcon from "@screencloud/screencloud-ui-components/.dist/components/icon";
import "./VideoDetail.scss";
import { VideoMessage, VideoProvider } from "../../types";
import Toolbar from "../shared/Toolbar";

interface Loom {
  expand: (selector: string) => void;
  textReplace: (
    text: string,
    options: { width: number; height: number }
  ) => Promise<string>;
}

// eslint-disable-next-line @typescript-eslint/no-var-requires
const loom: Loom = require("@loomhq/loom-embed");

interface VideoDetailProps {
  message: VideoMessage;
}

const VideoDetail = (
  props: VideoDetailProps
): ReactElement<VideoDetailProps> => {
  const { message } = props;
  const [videoEmbeddedContent, setVideoEmbeddedContent] = useState("");
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [videoSeekPosition, setVideoSeekPosition] = useState(0);
  const playerRef = useRef(null);
  const elementRef = useRef(null);

  const onSeekChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      setVideoSeekPosition(parseFloat(e.target.value));
      if (playerRef.current) {
        // @ts-ignore
        playerRef.current.seekTo(parseFloat(e.target.value));
      }
    },
    [playerRef]
  );

  const toggleFullScreen = useCallback((): void => {
    const newValue = !isFullScreen;
    if (newValue) {
      // @ts-ignore
      // eslint-disable-next-line react/no-find-dom-node
      screenfull.request(findDOMNode(elementRef.current));
    } else {
      // @ts-ignore
      screenfull.exit();
    }
    setIsFullScreen(newValue);
  }, [isFullScreen]);

  useEffect(() => {
    if (message.provider === VideoProvider.Loom)
      loom
        .textReplace(message.url || "", { width: 400, height: 225 })
        .then((res) => {
          setVideoEmbeddedContent(res);
        });
  }, [message]);

  return (
    <div ref={elementRef} className="video-message-container">
      <div
        className={isFullScreen ? "video-message full-screen" : "video-message"}
      >
        {videoEmbeddedContent && (
          <div
            className="embedded-video"
            dangerouslySetInnerHTML={{ __html: videoEmbeddedContent }}
          />
        )}
        {(message.provider === null ||
          message.provider === VideoProvider.Vimeo ||
          message.provider === VideoProvider.Wistia ||
          message.provider === VideoProvider.Youtube) && (
          <ReactPlayer
            ref={playerRef}
            url={message.url}
            playing={isVideoPlaying}
            onStart={() => setIsVideoPlaying(true)}
            onPlay={() => setIsVideoPlaying(true)}
            onPause={() => setIsVideoPlaying(false)}
            onProgress={(e) => setVideoSeekPosition(e.played)}
            controls={false}
            width="100%"
            height="100%"
          />
        )}
      </div>
      <Toolbar videoProvider={message.provider} isFullScreen={isFullScreen}>
        {message.provider !== VideoProvider.Loom && (
          <div className="video-controls">
            <ScreenCloudIcon
              name={isVideoPlaying ? "pause" : "play"}
              className="mark-as-seen"
              color="black"
              onClick={() => {
                setIsVideoPlaying(!isVideoPlaying);
              }}
            />
            <input
              type="range"
              min={0}
              max={0.999999}
              step="any"
              value={videoSeekPosition}
              onChange={onSeekChange}
            />
            <ScreenCloudIcon
              name={isFullScreen ? "exit-fullscreen" : "enter-fullscreen"}
              className="mark-as-seen"
              color="black"
              onClick={toggleFullScreen}
            />
          </div>
        )}
      </Toolbar>
    </div>
  );
};

export default VideoDetail;
