import React, { ReactElement } from "react";
import "./Profile.scss";
import Dropdown from "@screencloud/screencloud-ui-components/.dist/components/dropdown";
import DropdownMenu from "@screencloud/screencloud-ui-components/.dist/components/dropdown/menu";
import DropdownItem from "@screencloud/screencloud-ui-components/.dist/components/dropdown/item";
import Icon from "@screencloud/screencloud-ui-components/.dist/components/icon";
import Avatar from "./Avatar";

interface ProfileProps {
  name: string;
  profilePictureUrl: string;
  workspace: string;
  onLogOut: () => void;
}

const Profile = (props: ProfileProps): ReactElement<ProfileProps> => {
  const { name, profilePictureUrl, workspace, onLogOut } = props;
  const trigger = (
    <div className="profile-trigger">
      <Avatar url={profilePictureUrl} className="profile-picture" />
      <div className="profile-info">
        <span className="username">{name}</span>
        <span className="workspace">{workspace}</span>
      </div>
    </div>
  );
  return (
    <div className="profile">
      <Dropdown trigger={trigger}>
        <DropdownMenu>
          <DropdownItem onClick={onLogOut}>
            <div className="dropdown-item">
              <Icon name="logout" color="red" className="logout-icon" />
              <span className="logout-button">Logout</span>
            </div>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default Profile;
