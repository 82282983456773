import React, { ReactElement } from "react";
import { Progress } from "semantic-ui-react";
import "./ProgressBar.scss";

interface ProgressBarProps {
  total: number;
  current: number;
}

const ProgressBar = (
  props: ProgressBarProps
): ReactElement<ProgressBarProps> => {
  const { total, current } = props;
  return <Progress size="tiny" value={current} total={total} />;
};

export default ProgressBar;
