import React, { ReactElement, useContext, FunctionComponent } from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import { AuthContext } from "./context/AuthContext";

interface PrivateRouteProps {
  component: FunctionComponent;
  exact?: boolean;
  path: string;
}

const PrivateRoute = (
  props: PrivateRouteProps
): ReactElement<PrivateRouteProps> => {
  const { component, path, exact } = props;
  const location = useLocation();
  const authContext = useContext(AuthContext);
  return authContext.isLoggedIn ? (
    <Route component={component} path={path} exact={exact} />
  ) : (
    <Redirect to={`/login?redirectUrl=${location.pathname}`} />
  );
};

export default PrivateRoute;
