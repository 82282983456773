import React, {
  ReactElement,
  useEffect,
  useState,
  useCallback,
  ReactNode,
} from "react";
import "../shared/slick/slick.scss";
import "../shared/slick/slick-theme.scss";
import "./MessagePlayer.scss";
import { Message, WorkspaceProvider } from "../../types";
import MessageDetail from "../messages/MessageDetail";
import ScreenCloudIcon from "@screencloud/screencloud-ui-components/.dist/components/icon";
import { Icon } from "semantic-ui-react";
import Slider from "react-slick";
import EmojiPicker from "../shared/EmojiPicker";

interface MessagePlayerProps {
  messages: Message[];
  workspaceProvider: WorkspaceProvider | null;
  onMessageRead: (message: Message) => void;
  onMessageReaction: (message: Message, reaction: string) => void;
  onNextChannel: () => void;
  onMessageRemoved: (message: Message) => void;
}

const settings = {
  speed: 400,
  centerMode: true,
  centerPadding: "0px",
  draggable: false,
  slidesToShow: 1,
  infinite: false,
  variableWidth: true,
  focusOnChange: true,
  focusOnSelect: true,
  accessibility: true,
  slidesToScroll: 1,
  easing: "ease",
  prevArrow: (
    <div className="arrow">
      <ScreenCloudIcon name="arrow-left" />
    </div>
  ),
  nextArrow: (
    <div className="arrow">
      <ScreenCloudIcon name="arrow-right" />
    </div>
  ),
};

const storeReaction = (message: Message, emoji: string) => {
  let currentReactions: { id: string; emoji: string }[] = [];
  currentReactions = JSON.parse(
    localStorage.getItem("screenCloudSignalMessageReactions") || "[]"
  );
  if (currentReactions.map((r) => r.id).includes(message.id)) {
    const index = currentReactions.findIndex((r) => r.id === message.id);
    currentReactions[index].emoji = emoji;
  } else {
    currentReactions.push({ id: message.id, emoji });
  }
  localStorage.setItem(
    "screenCloudSignalMessageReactions",
    JSON.stringify(currentReactions)
  );
};

const retrieveReactions = () => {
  const reactions: { id: string; emoji: string }[] = JSON.parse(
    localStorage.getItem("screenCloudSignalMessageReactions") || "[]"
  );
  return reactions;
};

const MessagePlayer = (
  props: MessagePlayerProps
): ReactElement<MessagePlayerProps> => {
  const {
    messages,
    workspaceProvider,
    onMessageRead,
    onNextChannel,
    onMessageRemoved,
    onMessageReaction,
  } = props;
  const [currentAnimationClass, setCurrentAnimationClass] = useState("");
  const [allMessages, setAllMessages] = useState<Message[]>([]);
  const [messageReactions, setMessageReactions] = useState<
    { id: string; emoji: string }[]
  >([]);

  useEffect(() => {
    setAllMessages(messages);
  }, [messages]);

  useEffect(() => {
    setMessageReactions(retrieveReactions());
  }, []);

  useEffect(() => {
    const firstSlide = document.querySelector(".slick-current");
    if (firstSlide) {
      (firstSlide as HTMLDivElement).focus();
    }
  });

  const onReadMessage = useCallback(
    (message) => {
      const updatedMessages = allMessages.filter((m) => m.id !== message.id);
      setCurrentAnimationClass("seen");
      const timeout = setTimeout(() => {
        setAllMessages(updatedMessages);

        if (!updatedMessages.length) {
          onNextChannel();
        }
        setCurrentAnimationClass("");
        clearTimeout(timeout);
      }, 300);
    },
    [allMessages, onNextChannel]
  );

  const getMessageDetail = useCallback(
    (message: Message, index): ReactNode => {
      return (
        <MessageDetail
          key={index}
          className={currentAnimationClass}
          onSpaceBarPressed={() => {
            onMessageRead(message);
            onReadMessage(message);
          }}
          message={message}
          messageActions={
            workspaceProvider === WorkspaceProvider.Slack ? (
              <div className="actions">
                <EmojiPicker
                  emoji={
                    messageReactions.find((m) => m.id === message.id)?.emoji ||
                    null
                  }
                  onEmojiPicked={({ slackName, value }) => {
                    onMessageReaction(message, slackName);
                    storeReaction(message, value);
                  }}
                />
                <Icon
                  name="check"
                  className="mark-as-read"
                  color="green"
                  onClick={() => {
                    onMessageRead(message);
                    onReadMessage(message);
                  }}
                />
              </div>
            ) : (
              <div className="actions">
                <Icon
                  name="check"
                  className="mark-as-read"
                  color="green"
                  onClick={() => {
                    onMessageRead(message);
                    onReadMessage(message);
                  }}
                />
              </div>
            )
          }
          onMessageRemoved={onMessageRemoved}
        />
      );
    },
    [
      onMessageRead,
      onReadMessage,
      onMessageRemoved,
      currentAnimationClass,
      onMessageReaction,
      messageReactions,
      workspaceProvider,
    ]
  );

  return (
    <div
      className={
        allMessages.length === 1
          ? "message-player single-slide"
          : "message-player"
      }
    >
      {allMessages.length === 1 ? (
        <div className="message-detail-wrapper">
          {getMessageDetail(allMessages[0], 0)}
        </div>
      ) : (
        <Slider {...settings}>
          {allMessages.map((message, index) => {
            return getMessageDetail(message, index);
          })}
        </Slider>
      )}
    </div>
  );
};

export default MessagePlayer;
