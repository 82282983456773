import React, { ReactElement, useState, useContext, useEffect } from "react";
import Loader from "@screencloud/screencloud-ui-components/.dist/components/loader";
import Button from "@screencloud/screencloud-ui-components/.dist/components/button";
import Pagination from "@screencloud/screencloud-ui-components/.dist/components/pagination";
import "./History.scss";
import MessageViewer from "../../components/viewers/MessageViewer";
import Nav from "../../components/shared/Nav";
import { MessageCategory, Message, MessageCounts } from "../../types";
import { DataContext } from "../../context/DataContext";
import { Link } from "react-router-dom";
import CategoryList from "../../components/shared/CategoryList";

const categoryMap: { [key: string]: string } = {
  Personal: "saved",
  Subscription: "following",
  MustSee: "mustSee",
};

const PAGE_SIZE = 10;

const History = (): ReactElement<{}> => {
  const {
    getReadMessages,
    getReadMessageCounts,
    markMessageAsUnread,
    removeMessage,
    graphqlError,
  } = useContext(DataContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const [messageCounts, setMessageCounts] = useState<MessageCounts | null>(
    null
  );
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [
    currentCategory,
    setCurrentCategory,
  ] = useState<MessageCategory | null>(null);

  const onMessageRemoved = (message: Message): void => {
    removeMessage(message.id)
      .then(getReadMessageCounts)
      .then((counts) => {
        setMessageCounts(counts);
        setTotalPages(
          Math.ceil(
            // @ts-ignore
            messageCounts[
              categoryMap[
                currentCategory ? currentCategory : MessageCategory.MustSee
              ]
            ] / PAGE_SIZE
          )
        );
        setIsLoading(true);
        getReadMessages(
          currentCategory ? currentCategory : MessageCategory.MustSee,
          PAGE_SIZE,
          currentPage - 1
        )
          .then((messages) => {
            setMessages(messages);
            setIsLoading(false);
          })
          .catch(() => {
            setIsLoading(false);
            setIsError(true);
          });
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      });
  };

  useEffect(() => {
    setCurrentPage(1);
    setCurrentCategory(MessageCategory.MustSee);
  }, []);

  useEffect(() => {
    getReadMessageCounts()
      .then((messageCounts) => {
        setMessageCounts(messageCounts);
        setTotalPages(
          Math.ceil(
            // @ts-ignore
            messageCounts[
              categoryMap[
                currentCategory ? currentCategory : MessageCategory.MustSee
              ]
            ] / PAGE_SIZE
          )
        );
        setIsLoading(true);
        getReadMessages(
          currentCategory ? currentCategory : MessageCategory.MustSee,
          PAGE_SIZE,
          currentPage - 1
        )
          .then((messages) => {
            setMessages(messages);
            setIsLoading(false);
          })
          .catch(() => {
            setIsLoading(false);
            setIsError(true);
          });
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      });
  }, [currentPage, currentCategory, getReadMessageCounts, getReadMessages]);

  useEffect(() => {
    if (graphqlError) {
      setIsError(true);
    }
  }, [graphqlError]);

  return (
    <div className="history-container">
      <div className="left-panel">
        <Nav
          currentLocation="/history"
          title="History"
          subtitle="See past updates from each queue."
        >
          <CategoryList
            messageCounts={messageCounts}
            selectedCategory={currentCategory}
            onSelect={setCurrentCategory}
            showCheckMark={false}
          />
        </Nav>
      </div>
      <div className="right-panel">
        {isError ? (
          <div className="loading-message">
            There was an error while trying to load messages. Please refresh and
            try again.
          </div>
        ) : isLoading ? (
          <div className="loading-message">
            <Loader active size="massive" className="signal-loader" />
            <span>Loading messages...</span>
          </div>
        ) : messages.length && !!currentCategory ? (
          <>
            <MessageViewer
              messages={messages}
              messageActions={(message) => (
                <Button
                  outline
                  onClick={() => {
                    markMessageAsUnread((message as Message).id);
                    getReadMessageCounts()
                      .then((messageCounts) => {
                        setMessageCounts(messageCounts);
                        setTotalPages(
                          Math.ceil(
                            // @ts-ignore
                            messageCounts[
                              categoryMap[
                                currentCategory
                                  ? currentCategory
                                  : MessageCategory.MustSee
                              ]
                            ] / PAGE_SIZE
                          )
                        );
                        setIsLoading(true);
                        getReadMessages(
                          currentCategory
                            ? currentCategory
                            : MessageCategory.MustSee,
                          PAGE_SIZE,
                          currentPage - 1
                        ).then((messages) => {
                          setMessages(messages);
                          setIsLoading(false);
                        });
                      })
                      .catch(() => {
                        setIsLoading(false);
                        setIsError(true);
                      });
                  }}
                >
                  Mark as unread
                </Button>
              )}
              onMessageRemoved={onMessageRemoved}
            />
            <div className="paginator">
              {totalPages > 1 && (
                <Pagination
                  activePage={currentPage}
                  onPageChange={(_, data) => {
                    setCurrentPage(data.activePage as number);
                  }}
                  totalPages={totalPages}
                />
              )}
            </div>
          </>
        ) : (
          <div className="caught-up-message">
            <div className="error-message">
              There’s nothing in your history!
            </div>
            <div className="error-subtitle">
              You can start by browsing some channels and following their
              updates.
            </div>
            <div className="controls">
              <Button outline>
                <Link to="/browse">Browse channels</Link>
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default History;
