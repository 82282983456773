import React, { ReactElement, ReactNode, useRef } from "react";
import "./Toolbar.scss";
import loom from "../icons/loom.svg";
import zoom from "../icons/zoom.svg";
import youtube from "../icons/youtube.svg";

import { VideoProvider } from "../../types";
import useShowWhenActive from "../../hooks/useShowWhenActive";

const providerLogos = new Map<
  VideoProvider,
  { image: string | null; className: string }
>([
  [VideoProvider.Loom, { image: loom, className: "loom" }],
  [VideoProvider.Zoom, { image: zoom, className: "zoom" }],
  [VideoProvider.Youtube, { image: youtube, className: "youtube" }],
  [VideoProvider.Slack, { image: null, className: "" }],
  [VideoProvider.Wistia, { image: null, className: "" }],
]);

interface ToolbarProps {
  children?: ReactNode;
  videoProvider: VideoProvider;
  isFullScreen: boolean;
}

const Toolbar = (props: ToolbarProps): ReactElement<ToolbarProps> => {
  const { children, videoProvider, isFullScreen } = props;
  const toolbarRef = useRef(null);
  useShowWhenActive(toolbarRef, isFullScreen);
  const providerLogo = providerLogos.get(videoProvider);
  return (
    <div className="toolbar" ref={toolbarRef}>
      {children}
      <div className="source-logo">
        {providerLogo && providerLogo.image && (
          <img
            src={providerLogo.image}
            className={providerLogo.className}
            alt="Video Provider"
          />
        )}
      </div>
    </div>
  );
};

export default Toolbar;
