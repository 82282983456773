import React, { ReactElement, useEffect, useState, useContext } from "react";
import Nav from "../../components/shared/Nav";
import { useParams } from "react-router-dom";
import "./SignaledMessage.scss";
import TopBar from "../../components/shared/TopBar";
import { Message, ChannelSubscription } from "../../types";
import { DataContext } from "../../context/DataContext";
import Loader from "@screencloud/screencloud-ui-components/.dist/components/loader";
import Button from "@screencloud/screencloud-ui-components/.dist/components/button";
import MessageDetail from "../../components/messages/MessageDetail";

const isSubscribed = (
  subscriptions: ChannelSubscription[],
  channelId: string
): boolean => subscriptions.some((sub) => sub.channel.id === channelId);

const SignaledMessage = (): ReactElement<{}> => {
  const { id } = useParams();
  const {
    getMessage,
    getChannelSubscriptions,
    subscribeToChannel,
    unsubscribeFromChannel,
    graphqlError,
  } = useContext(DataContext);
  const [message, setMessage] = useState<Message>((null as unknown) as Message);
  const [subscriptions, setSubscriptions] = useState<ChannelSubscription[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  useEffect(() => {
    if (id) {
      setIsLoading(true);
      getMessage(id)
        .then((message) => {
          setMessage(message);
          return getChannelSubscriptions();
        })
        .then((subscriptions) => {
          setSubscriptions(subscriptions);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
          setIsError(true);
        });
    } else {
      setIsError(false);
    }
  }, [id, getMessage, getChannelSubscriptions]);

  useEffect(() => {
    if (graphqlError) {
      setIsError(true);
    }
  }, [graphqlError]);

  return (
    <div className="signaled-message-container">
      <div className="left-panel">
        <Nav currentLocation="" title="" subtitle="" />
      </div>
      <div className="right-panel">
        {!!message && !isLoading && !isError && (
          <>
            <TopBar
              title={message.channel.name}
              subtitle={message.channel.description}
            >
              {isSubscribed(subscriptions, message.channel.id) ? (
                <Button
                  color="green"
                  className="following"
                  onClick={() => {
                    unsubscribeFromChannel(message.channel.id)
                      .then(getChannelSubscriptions)
                      .then(setSubscriptions);
                  }}
                >
                  <span className="following">Following</span>
                  <span className="unfollow">Unfollow</span>
                </Button>
              ) : (
                <Button
                  outline
                  className="follow"
                  onClick={() => {
                    subscribeToChannel(message.channel.id)
                      .then(getChannelSubscriptions)
                      .then(setSubscriptions);
                  }}
                >
                  Follow Channel
                </Button>
              )}
            </TopBar>
            <div className="message">
              <MessageDetail message={message} />
            </div>
          </>
        )}
        {isError && (
          <div className="loading-message">
            There was an error while trying to load messages. Please refresh and
            try again.
          </div>
        )}
        {isLoading && (
          <div className="loading-message">
            <Loader active size="massive" className="signal-loader" />
            <span>Loading message...</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default SignaledMessage;
