import React, { ReactElement, useState, useEffect, useRef } from "react";
import Popover from "@screencloud/screencloud-ui-components/.dist/components/popover";
import emojiIcon from "../icons/emoji.svg";
import "./EmojiPicker.scss";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";

interface EmojiPickerProps {
  emoji: string | null;
  onEmojiPicked: (event: { slackName: string; value: string }) => void;
}

const emojis = [
  { value: "👍", label: "Thumbs up", slackName: "thumbsup" },
  { value: "❤️", label: "Heart", slackName: "heart" },
  { value: "😄", label: "Smile", slackName: "smile" },
  { value: "😮", label: "Open mouth", slackName: "open_mouth" },
  { value: "😞", label: "Disappointed", slackName: "disappointed" },
  { value: "😠", label: "Angry", slackName: "angry" },
];

const EmojiPicker = (
  props: EmojiPickerProps
): ReactElement<EmojiPickerProps> => {
  const { onEmojiPicked, emoji } = props;
  const [currentEmoji, setCurrentEmoji] = useState("");
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [currentEmojiClass, setCurrentEmojiClass] = useState("");
  const emojiPickerRef = useRef(null);
  useOnClickOutside(emojiPickerRef, () => setIsPickerOpen(false));

  useEffect(() => {
    setIsPickerOpen(false);
    setCurrentEmojiClass("animate");
    const timeout = setTimeout(() => {
      setCurrentEmojiClass("");
      clearTimeout(timeout);
    }, 400);
  }, [currentEmoji]);

  useEffect(() => {
    if (emoji) {
      setCurrentEmoji(emoji);
    }
  }, [emoji]);

  return (
    <div ref={emojiPickerRef} className="emoji-picker-container">
      <Popover
        className="emoji-popup"
        offset="0, 7px"
        flowing
        position="top center"
        open={isPickerOpen}
        trigger={
          currentEmoji ? (
            <span
              className={`current-emoji ${currentEmojiClass}`}
              onClick={() => setIsPickerOpen(!isPickerOpen)}
            >
              {currentEmoji}
            </span>
          ) : (
            <img
              className="emoji-reaction"
              src={emojiIcon}
              alt="React"
              onClick={() => setIsPickerOpen(!isPickerOpen)}
            />
          )
        }
      >
        <div className="emojis">
          {emojis.map((emoji) => {
            return (
              <span
                key={emoji.value}
                role="img"
                className="emoji"
                aria-label={emoji.label}
                onClick={() => {
                  setCurrentEmoji(emoji.value);
                  onEmojiPicked({
                    slackName: emoji.slackName,
                    value: emoji.value,
                  });
                }}
              >
                {emoji.value}
              </span>
            );
          })}
        </div>
      </Popover>
    </div>
  );
};

export default EmojiPicker;
