import React, { ReactElement, useRef } from "react";
import NoProfilePic from "../icons/no-profile-pic.svg";

interface AvatarProps {
  url?: string;
  className?: string;
}

const generateRandomColor = () => {
  var randomColor = "#" + Math.floor(Math.random() * 16777215).toString(16);
  return randomColor;
};

const Avatar = (props: AvatarProps): ReactElement<AvatarProps> => {
  const { url, className } = props;
  const backgroundColor = useRef(generateRandomColor());

  return (
    <img
      src={!!url ? url : NoProfilePic}
      srcSet={url?.replace("_72.", "_512.")}
      style={
        url ? {} : { backgroundColor: backgroundColor.current, opacity: 0.5 }
      }
      alt="Profile"
      className={className ? className : ""}
    />
  );
};

export default Avatar;
