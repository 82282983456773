import React, { ReactElement, useState, useContext, useEffect } from "react";
import Button from "@screencloud/screencloud-ui-components/.dist/components/button";
import Loader from "@screencloud/screencloud-ui-components/.dist/components/loader";
import Nav from "../../components/shared/Nav";
import "./Manage.scss";
import { ChannelSubscription } from "../../types";
import { DataContext } from "../../context/DataContext";
import { Link } from "react-router-dom";

const Manage = (): ReactElement<{}> => {
  const [subscriptions, setSubscriptions] = useState<ChannelSubscription[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const {
    getChannelSubscriptions,
    unsubscribeFromChannel,
    graphqlError,
  } = useContext(DataContext);

  useEffect(() => {
    if (graphqlError) {
      setIsError(true);
    }
  }, [graphqlError]);

  useEffect(() => {
    getChannelSubscriptions()
      .then((channelSubscriptions) => {
        setSubscriptions(channelSubscriptions);
        setIsLoading(false);
      })
      .catch(() => setIsError(true));
  }, [getChannelSubscriptions]);

  return (
    <div className="manage-container">
      <div className="left-panel">
        <Nav
          currentLocation="/manage"
          title="Manage"
          subtitle="View and set your preferences."
        />
      </div>
      <div className="right-panel">
        <div className="subscriptions-title">
          <h1>My Channel Subscriptions</h1>
        </div>
        <div className="subscriptions">
          {isLoading ? (
            <div className="loading-message">
              <Loader active size="massive" className="signal-loader" />
              <span>Loading channels...</span>
            </div>
          ) : subscriptions.length ? (
            subscriptions.map((subscription, index) => {
              return (
                <div key={index} className="followed-channel">
                  <span>{`#${subscription.channel.name}`}</span>
                  <Button
                    danger
                    onClick={() => {
                      unsubscribeFromChannel(subscription.channel.id)
                        .then(() => {
                          setIsLoading(true);
                          return getChannelSubscriptions();
                        })
                        .then((channelSubscriptions) => {
                          setSubscriptions(channelSubscriptions);
                          setIsLoading(false);
                        });
                    }}
                  >
                    Unfollow
                  </Button>
                </div>
              );
            })
          ) : isError ? (
            <div className="no-subscriptions-message">
              There was an error while trying to load channels. Please refresh
              and try again.
            </div>
          ) : (
            <div className="no-subscriptions-message">
              You are currently not following any channels.
              <br />
              <Button outline className="browse-button">
                <Link to="/browse">Browse Channels</Link>
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Manage;
