export const getChannelSubscriptionsQuery = `
  {
    allChannelSubscriptions{
      createdAt,
      channel {
        id
        name
        description
        topic
      }
    }
  }`;
