import React, { ReactElement, ReactNode } from "react";
import "./TopBar.scss";

interface TopBarProps {
  title: string;
  subtitle: string;
  children?: ReactNode;
}

const TopBar = (props: TopBarProps): ReactElement<TopBarProps> => {
  const { title, subtitle, children } = props;
  return (
    <div className="top-bar">
      <div className="title-block">
        <h1>{title}</h1>
        <p dangerouslySetInnerHTML={{ __html: subtitle }}></p>
      </div>
      <span>{children}</span>
    </div>
  );
};

export default TopBar;
