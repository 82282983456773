import React, { ReactElement, useState, useEffect, useContext } from "react";
import Loader from "@screencloud/screencloud-ui-components/.dist/components/loader";
import Button from "@screencloud/screencloud-ui-components/.dist/components/button";
import Nav from "../../components/shared/Nav";
import { Card } from "semantic-ui-react";
import ListItem from "../../components/shared/ListItem";
import { Channel, Message, ChannelSubscription } from "../../types";
import { DataContext } from "../../context/DataContext";
import "./Browse.scss";
import MessageViewer from "../../components/viewers/MessageViewer";
import TopBar from "../../components/shared/TopBar";

const Browse = (): ReactElement<{}> => {
  const {
    getChannels,
    getChannelSubscriptions,
    getMessagesForChannel,
    subscribeToChannel,
    unsubscribeFromChannel,
    removeMessage,
    graphqlError,
  } = useContext(DataContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [channels, setChannels] = useState<Channel[]>([]);
  const [subscriptions, setSubscriptions] = useState<ChannelSubscription[]>([]);
  const [messages, setMessages] = useState<Message[]>([]);
  const [selectedChannel, setSelectedChannel] = useState<Channel>(
    (null as unknown) as Channel
  );

  const isSubscribed = (
    subscriptions: ChannelSubscription[],
    channelId: string
  ): boolean => subscriptions.some((sub) => sub.channel.id === channelId);

  const onMessageRemoved = (message: Message): void => {
    setIsLoading(true);

    removeMessage(message.id)
      .then(() => getMessagesForChannel(selectedChannel.id))
      .then((messages) => {
        setMessages(messages);
        setIsLoading(false);
      })
      .catch(() => {
        setIsError(true);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getChannelSubscriptions()
      .then((channelSubscriptions) => {
        setSubscriptions(channelSubscriptions);
        getChannels()
          .then((fetchedChannels) => {
            const sortedChannels = fetchedChannels.sort((a, b) => {
              const includes = (channel: Channel) =>
                channelSubscriptions
                  .map((c) => c.channel.id)
                  .includes(channel.id);
              if (includes(a) && includes(b)) {
                return 0;
              } else {
                if (includes(a)) {
                  return 1;
                } else {
                  return -1;
                }
              }
            });
            setChannels(sortedChannels);
            setSelectedChannel(sortedChannels[0]);
            setIsLoading(false);
          })
          .catch(() => {
            setIsLoading(false);
            setIsError(true);
          });
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      });
  }, [getChannelSubscriptions, getChannels]);

  useEffect(() => {
    const sortedChannels = channels.sort((a, b) => {
      const includes = (channel: Channel) =>
        subscriptions.map((c) => c.channel.id).includes(channel.id);
      if (includes(a) && includes(b)) {
        return 0;
      } else {
        if (includes(a)) {
          return 1;
        } else {
          return -1;
        }
      }
    });
    setChannels(sortedChannels);
    // eslint-disable-next-line
  }, [subscriptions]);

  useEffect(() => {
    if (selectedChannel) {
      setIsLoading(true);
      getMessagesForChannel(selectedChannel.id).then((messages) => {
        setMessages(messages);
        setIsLoading(false);
      });
    }
  }, [selectedChannel, getMessagesForChannel]);

  useEffect(() => {
    if (graphqlError) {
      setIsError(true);
    }
  }, [graphqlError]);

  return (
    <div className="browse-container">
      <div className="left-panel">
        <Nav
          currentLocation="/browse"
          title="Browse"
          subtitle="View updates from different channels."
        >
          <Card.Group>
            {channels
              .filter((c) => !isSubscribed(subscriptions, c.id))
              .map((channel, index) => (
                <ListItem
                  key={index}
                  title={`#${channel.name}`}
                  subtitle={""}
                  selected={
                    selectedChannel && selectedChannel.id === channel.id
                  }
                  onSelect={() => {
                    setSelectedChannel(channel);
                  }}
                />
              ))}
            {!!channels.filter((c) => isSubscribed(subscriptions, c.id))
              .length && (
              <>
                <div className="section-header">Following</div>
                {channels
                  .filter((c) => isSubscribed(subscriptions, c.id))
                  .map((channel, index) => (
                    <ListItem
                      key={index}
                      title={`#${channel.name}`}
                      subtitle={""}
                      selected={
                        selectedChannel && selectedChannel.id === channel.id
                      }
                      onSelect={() => {
                        setSelectedChannel(channel);
                      }}
                    />
                  ))}
              </>
            )}
          </Card.Group>
        </Nav>
      </div>
      <div className="right-panel">
        {isLoading ? (
          <div className="loading-message">
            <Loader active size="massive" className="signal-loader" />
            <span>Loading messages...</span>
          </div>
        ) : isError ? (
          <div className="loading-message">
            There was an error while trying to load messages. Please refresh and
            try again.
          </div>
        ) : (
          <>
            {selectedChannel && (
              <TopBar
                title={`#${selectedChannel.name}`}
                subtitle={
                  selectedChannel.description
                    ? selectedChannel.description
                    : selectedChannel.topic
                }
              >
                {isSubscribed(subscriptions, selectedChannel.id) ? (
                  <Button
                    color="green"
                    className="following"
                    onClick={() => {
                      unsubscribeFromChannel(selectedChannel.id)
                        .then(() => {
                          setIsLoading(true);
                          return getChannels();
                        })
                        .then((channels) => {
                          setChannels(channels);
                          return getChannelSubscriptions();
                        })
                        .then((subscriptions) => {
                          setSubscriptions(subscriptions);
                          setIsLoading(false);
                        });
                    }}
                  >
                    <span className="following">Following</span>
                    <span className="unfollow">Unfollow</span>
                  </Button>
                ) : (
                  <Button
                    outline
                    className="follow"
                    onClick={() => {
                      subscribeToChannel(selectedChannel.id)
                        .then(() => {
                          setIsLoading(true);
                          return getChannels();
                        })
                        .then((channels) => {
                          setChannels(channels);
                          return getChannelSubscriptions();
                        })
                        .then((subscriptions) => {
                          setSubscriptions(subscriptions);
                          setIsLoading(false);
                        });
                    }}
                  >
                    Follow
                  </Button>
                )}
              </TopBar>
            )}
            <div className="message-viewer-wrapper">
              <MessageViewer
                messages={messages}
                messageActions={null}
                onMessageRemoved={onMessageRemoved}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Browse;
