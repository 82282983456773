import React, {
  createContext,
  ReactElement,
  ReactNode,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";
import ApolloClient, { gql } from "apollo-boost";
import {
  MessageCategory,
  Message,
  Channel,
  MessageCounts,
  ChannelSubscription,
  SessionInfo,
  Workspace,
} from "../types";
import { User } from "../types";
import { AuthContext, CHECK_SESSION_INTERVAL } from "./AuthContext";
import {
  unreadMessageCountQuery,
  getMessagesQuery,
  readMessageCountQuery,
  getMessageQuery,
  getReadMessagesQuery,
  getMessagesForChannelQuery,
  getChannelsQuery,
  getChannelSubscriptionsQuery,
  getCurrentUserQuery,
  getWorkspacesQuery,
  getSlackUserQuery,
} from "./graphql/queries";
import {
  markMessageAsReadMutation,
  markMessageAsUnreadMutation,
  subscribeToChannelMutation,
  unsubscribeFromChannelMutation,
  removeMessageMutation,
  markAllMessagesAsUnreadMutation,
  reactToMessageMutation,
} from "./graphql/mutations";

const DataContext = createContext({
  getMessages: (null as unknown) as (
    category: MessageCategory
  ) => Promise<Message[]>,
  getMessage: (null as unknown) as (messageId: string) => Promise<Message>,
  getReadMessages: (null as unknown) as (
    category: MessageCategory,
    limit: number,
    offset: number
  ) => Promise<Message[]>,
  getUnreadMessageCounts: (null as unknown) as () => Promise<MessageCounts>,
  getReadMessageCounts: (null as unknown) as () => Promise<MessageCounts>,
  getChannels: (null as unknown) as () => Promise<Channel[]>,
  getChannelSubscriptions: (null as unknown) as () => Promise<
    ChannelSubscription[]
  >,
  getMessagesForChannel: (null as unknown) as (
    channelId: string
  ) => Promise<Message[]>,
  subscribeToChannel: (null as unknown) as (channelId: string) => Promise<void>,
  unsubscribeFromChannel: (null as unknown) as (
    channelId: string
  ) => Promise<void>,
  markMessageAsRead: (null as unknown) as (messageId: string) => Promise<void>,
  markMessageAsUnread: (null as unknown) as (
    messageId: string
  ) => Promise<void>,
  removeMessage: (null as unknown) as (messageId: string) => Promise<void>,
  reactToMessage: (null as unknown) as (
    messageId: string,
    emoji: string
  ) => Promise<void>,
  markAllMessagesAsUnread: (null as unknown) as () => Promise<void>,
  getCurrentUser: (null as unknown) as () => Promise<User>,
  getWorkspaces: (null as unknown) as () => Promise<Workspace[]>,
  getSlackUser: (null as unknown) as (id: string) => Promise<User | null>,
  currentUser: (null as unknown) as User,
  graphqlError: null,
});

interface DataProviderProps {
  children: ReactNode;
}

const DataProvider = (
  props: DataProviderProps
): ReactElement<DataProviderProps> => {
  const {
    isLoggedIn,
    getSessionInfo,
    checkAndRefreshSession,
    currentSession,
  } = useContext(AuthContext);
  const [currentUser, setCurrentUser] = useState<User>(
    (null as unknown) as User
  );
  const [graphqlClient, setGraphqlClient] = useState<ApolloClient<any>>(
    (null as unknown) as ApolloClient<any>
  );

  const [graphqlError, setGraphqlError] = useState(null as any);

  useEffect(() => {
    setGraphqlClient(
      new ApolloClient({
        uri: `${process.env.REACT_APP_API_BASE_URL}/graphql`,
        onError: ({ networkError, graphQLErrors, operation, forward }) => {
          if (networkError) {
            setGraphqlError(new Error(networkError?.message));
          } else if (graphQLErrors?.length) {
            setGraphqlError(
              new Error(graphQLErrors.map((e) => e.message).join(", "))
            );
          }
          forward(operation);
        },
        request: async (operation) => {
          const sessionInfo = getSessionInfo();
          if (
            sessionInfo.expiresAt - new Date().valueOf() <=
            CHECK_SESSION_INTERVAL * 2
          ) {
            const newSessionInfo = (await checkAndRefreshSession()) as SessionInfo;
            operation.setContext({
              headers: {
                authorization: `Bearer ${newSessionInfo.token}`,
              },
            });
          } else {
            operation.setContext({
              headers: {
                authorization: `Bearer ${sessionInfo.token}`,
              },
            });
          }
        },
      })
    );
  }, [getSessionInfo, checkAndRefreshSession]);

  const getCurrentUser = useCallback((): Promise<User> => {
    return graphqlClient
      .query<{ currentUser: User }>({
        query: gql`
          ${getCurrentUserQuery},
        `,
        fetchPolicy: "no-cache",
      })
      .then((response) => response.data.currentUser)
      .catch(() => (null as unknown) as User);
  }, [graphqlClient]);

  useEffect(() => {
    if (graphqlClient && isLoggedIn) {
      getCurrentUser().then(setCurrentUser);
    }
  }, [graphqlClient, getCurrentUser, isLoggedIn, currentSession]);

  const getUnreadMessageCounts = useCallback((): Promise<MessageCounts> => {
    return graphqlClient
      .query<{ unreadMessageCount: MessageCounts }>({
        query: gql`
          ${unreadMessageCountQuery}
        `,
        fetchPolicy: "no-cache",
      })
      .then((response) => response.data.unreadMessageCount)
      .catch(() => ({ saved: 0, following: 0, mustSee: 0 }));
  }, [graphqlClient]);

  const getMessages = useCallback(
    (category: MessageCategory): Promise<Message[]> => {
      const query = getMessagesQuery(category);

      return graphqlClient
        .query<{ allUnreadMessages: Message[] }>({
          query: gql`
          ${query},
        `,
          fetchPolicy: "no-cache",
        })
        .then((response) => response.data.allUnreadMessages)
        .catch(() => []);
    },
    [graphqlClient]
  );

  const getReadMessageCounts = useCallback((): Promise<MessageCounts> => {
    return graphqlClient
      .query<{ readMessageCount: MessageCounts }>({
        query: gql`
          ${readMessageCountQuery}
        `,
        fetchPolicy: "no-cache",
      })
      .then((response) => response.data.readMessageCount)
      .catch(() => ({ saved: 0, following: 0, mustSee: 0 }));
  }, [graphqlClient]);

  const getMessage = useCallback(
    (id: string): Promise<Message> => {
      const query = getMessageQuery(id);

      return graphqlClient
        .query<{ getMessage: Message }>({
          query: gql`
          ${query},
        `,
          fetchPolicy: "no-cache",
        })
        .then((response) => response.data.getMessage)
        .catch(() => (null as unknown) as Message);
    },
    [graphqlClient]
  );

  const getReadMessages = useCallback(
    (
      category: MessageCategory,
      limit: number = 2,
      offset: number = 0
    ): Promise<Message[]> => {
      const query = getReadMessagesQuery(category, limit, offset);

      return graphqlClient
        .query<{ allReadMessages: Message[] }>({
          query: gql`
          ${query},
        `,
          fetchPolicy: "no-cache",
        })
        .then((response) => response.data.allReadMessages)
        .catch(() => []);
    },
    [graphqlClient]
  );

  const getMessagesForChannel = useCallback(
    (channelId: string): Promise<Message[]> => {
      const query = getMessagesForChannelQuery(channelId);

      return graphqlClient
        .query<{ allChannelMessages: Message[] }>({
          query: gql`
            ${query}
          `,
          fetchPolicy: "no-cache",
        })
        .then((response) => response.data.allChannelMessages)
        .catch(() => []);
    },
    [graphqlClient]
  );

  const getChannels = useCallback((): Promise<Channel[]> => {
    return graphqlClient
      .query<{ allChannels: Channel[] }>({
        query: gql`
          ${getChannelsQuery},
        `,
        fetchPolicy: "no-cache",
      })
      .then((response) => response.data.allChannels)
      .catch(() => []);
  }, [graphqlClient]);

  const getChannelSubscriptions = useCallback((): Promise<
    ChannelSubscription[]
  > => {
    return graphqlClient
      .query<{ allChannelSubscriptions: ChannelSubscription[] }>({
        query: gql`
          ${getChannelSubscriptionsQuery},
        `,
        fetchPolicy: "no-cache",
      })
      .then((response) => response.data.allChannelSubscriptions)
      .catch(() => []);
  }, [graphqlClient]);

  const getSlackUser = useCallback(
    (userId: string): Promise<User | null> => {
      const query = getSlackUserQuery(userId);

      return graphqlClient
        .query<{ getSlackUser: User }>({
          query: gql`
            ${query}
          `,
          fetchPolicy: "no-cache",
        })
        .then((response) => response.data.getSlackUser)
        .catch(() => null);
    },
    [graphqlClient]
  );

  const getWorkspaces = useCallback(() => {
    return graphqlClient
      .query<{ allWorkspaces: Workspace[] }>({
        query: gql`
          ${getWorkspacesQuery},
        `,
        fetchPolicy: "no-cache",
      })
      .then((response) => response.data.allWorkspaces)
      .catch(() => []);
  }, [graphqlClient]);

  const markMessageAsRead = useCallback(
    (messageId: string): Promise<void> => {
      return graphqlClient
        .mutate({
          mutation: gql`
            ${markMessageAsReadMutation}
          `,
          variables: {
            messageId,
          },
        })
        .then(() => Promise.resolve())
        .catch((error) => {
          if (error && error.message.includes("already_reacted")) {
            return Promise.resolve();
          } else {
            Promise.reject(error);
          }
        });
    },
    [graphqlClient]
  );

  const markMessageAsUnread = useCallback(
    (messageId: string): Promise<void> => {
      return graphqlClient
        .mutate({
          mutation: gql`
            ${markMessageAsUnreadMutation}
          `,
          variables: {
            messageId,
          },
        })
        .then(() => Promise.resolve())
        .catch((error) => {
          if (error && error.message.includes("already_reacted")) {
            return Promise.resolve();
          } else {
            Promise.reject(error);
          }
        });
    },
    [graphqlClient]
  );

  const subscribeToChannel = useCallback(
    (channelId: string): Promise<void> => {
      return graphqlClient
        .mutate({
          mutation: gql`
            ${subscribeToChannelMutation}
          `,
          variables: {
            channelId,
          },
        })
        .then(() => Promise.resolve());
    },
    [graphqlClient]
  );

  const unsubscribeFromChannel = useCallback(
    (channelId: string): Promise<void> => {
      return graphqlClient
        .mutate({
          mutation: gql`
            ${unsubscribeFromChannelMutation}
          `,
          variables: {
            channelId,
          },
        })
        .then(() => Promise.resolve());
    },
    [graphqlClient]
  );

  const removeMessage = useCallback(
    (messageId: string): Promise<void> => {
      return graphqlClient
        .mutate({
          mutation: gql`
            ${removeMessageMutation}
          `,
          variables: {
            messageId,
          },
        })
        .then(() => Promise.resolve())
        .catch((error) => Promise.reject(error));
    },
    [graphqlClient]
  );

  const reactToMessage = useCallback(
    (messageId: string, emoji: string): Promise<void> => {
      return graphqlClient
        .mutate({
          mutation: gql`
            ${reactToMessageMutation}
          `,
          variables: {
            messageId,
            emoji,
          },
        })
        .then(() => Promise.resolve())
        .catch((error) => Promise.reject(error));
    },
    [graphqlClient]
  );

  const markAllMessagesAsUnread = useCallback((): Promise<void> => {
    return graphqlClient
      .mutate({
        mutation: gql`
          ${markAllMessagesAsUnreadMutation}
        `,
      })
      .then(() => Promise.resolve())
      .catch((error) => Promise.reject(error));
  }, [graphqlClient]);

  return (
    graphqlClient && (
      <DataContext.Provider
        value={{
          getMessages,
          getMessage,
          getReadMessages,
          getUnreadMessageCounts,
          getReadMessageCounts,
          getChannels,
          getChannelSubscriptions,
          getMessagesForChannel,
          subscribeToChannel,
          unsubscribeFromChannel,
          markMessageAsRead,
          markMessageAsUnread,
          removeMessage,
          reactToMessage,
          markAllMessagesAsUnread,
          getCurrentUser,
          getWorkspaces,
          getSlackUser,
          currentUser,
          graphqlError,
        }}
      >
        {props.children}
      </DataContext.Provider>
    )
  );
};

const DataConsumer = DataContext.Consumer;

export { DataProvider, DataConsumer, DataContext };
