import React, { ReactElement, ReactNode } from "react";
import classNames from "classnames";
import "./ListItem.scss";
import Play from "../icons/play.svg";

interface ListItemProps {
  title: string;
  subtitle: string;
  selected: boolean;
  onSelect?: () => void;
  children?: ReactNode;
  disabled?: boolean;
  className?: string;
}

const ListItem = (props: ListItemProps): ReactElement<ListItemProps> => {
  const {
    title,
    subtitle,
    selected,
    onSelect,
    children,
    disabled,
    className,
  } = props;
  return (
    <div
      className={classNames(
        "list-item",
        { selected: selected },
        { disabled: disabled },
        className
      )}
      onClick={onSelect ? () => onSelect() : () => {}}
    >
      {selected && <img src={Play} alt="play" />}
      <div className="title-block">
        <div className="item-title">{title}</div>
        <div className="item-subtitle">
          <span>{subtitle}</span>
        </div>
      </div>
      <div className="badge">{children}</div>
    </div>
  );
};

export default ListItem;
