import React, {
  createContext,
  useState,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
} from "react";
import { SessionInfo, Workspace } from "../types";

export const REFRESH_SESSION_URL = `${process.env.REACT_APP_API_BASE_URL}/session/refresh`;
export const CHECK_SESSION_INTERVAL = 30000;

const getSessionInfo = (): SessionInfo => {
  return {
    token: localStorage.getItem("screenCloudSignalUserToken") || "",
    refreshToken: localStorage.getItem("screenCloudSignalRefreshToken") || "",
    expiresAt: Number(localStorage.getItem("screenCloudSignalTokenExpiresAt")),
  };
};

const getWorkspace = (): Workspace | null => {
  const workspace = localStorage.getItem("screenCloudSignalWorkspace");
  if (workspace) {
    return JSON.parse(workspace) as Workspace;
  }
  return null;
};

const AuthContext = createContext({
  getSessionInfo,
  setSessionInfo: (null as unknown) as (sessionInfo: SessionInfo) => void,
  getWorkspace,
  setWorkspace: (null as unknown) as (workspace: Workspace) => void,
  checkAndRefreshSession: (null as unknown) as () => Promise<
    SessionInfo | unknown
  >,
  currentSession: (null as unknown) as SessionInfo,
  currentWorkspace: (null as unknown) as Workspace | null,
  getUserToken: (null as unknown) as () => string | null,
  isLoggedIn: false,
  logOut: (null as unknown) as () => void,
});

interface AuthProviderProps {
  children: ReactNode;
}

const getUserToken = (): string | null => {
  const userToken = localStorage.getItem("screenCloudSignalUserToken");
  return userToken;
};

const AuthProvider = (
  props: AuthProviderProps
): ReactElement<AuthProviderProps> => {
  const [isLoggedIn, setIsLoggedIn] = useState(!!getUserToken());
  const [currentSession, setCurrentSession] = useState({
    token: localStorage.getItem("screenCloudSignalUserToken") || "",
    refreshToken: localStorage.getItem("screenCloudSignalRefreshToken") || "",
    expiresAt: Number(localStorage.getItem("screenCloudSignalTokenExpiresAt")),
  });

  const [currentWorkspace, setCurrentWorkspace] = useState(
    (null as unknown) as Workspace | null
  );

  const setSessionInfo = useCallback((sessionInfo: SessionInfo) => {
    localStorage.setItem("screenCloudSignalUserToken", sessionInfo.token);
    localStorage.setItem(
      "screenCloudSignalRefreshToken",
      sessionInfo.refreshToken
    );
    localStorage.setItem(
      "screenCloudSignalTokenExpiresAt",
      sessionInfo.expiresAt.toString()
    );
    setCurrentSession(sessionInfo);
    setIsLoggedIn(true);
  }, []);

  const setWorkspace = useCallback((workspace: Workspace) => {
    setCurrentWorkspace(workspace);
    localStorage.setItem(
      "screenCloudSignalWorkspace",
      JSON.stringify(workspace)
    );
  }, []);

  const logOut = useCallback(() => {
    localStorage.removeItem("screenCloudSignalUserToken");
    setIsLoggedIn(false);
  }, []);

  useEffect(() => {
    const sessionInfo = getSessionInfo();
    setIsLoggedIn(
      !!(sessionInfo && sessionInfo.token && sessionInfo.refreshToken)
    );
    setCurrentSession(getSessionInfo());
    const workspace = getWorkspace();
    if (workspace) {
      setCurrentWorkspace(workspace);
    } else {
      logOut();
    }

    const onStorageChanged = () => {
      const sessionInfo = getSessionInfo();
      if (!sessionInfo.token || !sessionInfo.refreshToken) {
        logOut();
      }
    };

    window.addEventListener("storage", onStorageChanged);

    return () => window.removeEventListener("storage", onStorageChanged);
  }, [logOut]);

  const checkAndRefreshSession = useCallback(() => {
    const sessionInfo = getSessionInfo();
    const tokenExpiresAt = sessionInfo.expiresAt;
    return new Promise((resolve, reject) => {
      if (!Number.isNaN(tokenExpiresAt)) {
        if (
          tokenExpiresAt - new Date().valueOf() <=
          CHECK_SESSION_INTERVAL * 2
        ) {
          if (sessionInfo.refreshToken) {
            fetch(REFRESH_SESSION_URL, {
              method: "POST",
              headers: { Authorization: `Bearer ${sessionInfo.refreshToken}` },
            })
              .then((response) => response.json())
              .then((newSessionInfo: SessionInfo) => {
                setSessionInfo(newSessionInfo);
                resolve(newSessionInfo);
              })
              .catch(() => {
                logOut();
                reject();
              });
          } else {
            logOut();
            reject();
          }
        } else {
          resolve(sessionInfo);
        }
      } else {
        logOut();
        reject();
      }
    });
  }, [setSessionInfo, logOut]);

  useEffect(() => {
    const sessionInfo = getSessionInfo();
    setCurrentSession(sessionInfo);
    checkAndRefreshSession();

    if (isLoggedIn) {
      const checkSessionInterval = setInterval(
        checkAndRefreshSession,
        CHECK_SESSION_INTERVAL
      );

      return () => clearInterval(checkSessionInterval);
    }
  }, [isLoggedIn, setSessionInfo, checkAndRefreshSession]);

  return (
    <AuthContext.Provider
      value={{
        setSessionInfo,
        currentWorkspace,
        getWorkspace,
        setWorkspace,
        checkAndRefreshSession,
        currentSession,
        getUserToken,
        getSessionInfo,
        isLoggedIn,
        logOut,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

const AuthConsumer = AuthContext.Consumer;

export { AuthProvider, AuthConsumer, AuthContext };
