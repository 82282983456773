import React, { ReactElement } from "react";
import "./PhotoDetail.scss";
import { Message } from "../../types";
import Zoom from "react-medium-image-zoom";

interface PhotoDetailProps {
  message: Message;
}

const PhotoDetail = (
  props: PhotoDetailProps
): ReactElement<PhotoDetailProps> => {
  const { message } = props;

  return (
    <Zoom>
      <div
        className="photo-message"
        style={{ backgroundImage: `url(${message.url})` }}
      />
    </Zoom>
  );
};

export default PhotoDetail;
