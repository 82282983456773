import React, { ReactElement, ReactNode, useContext } from "react";
import Logo from "../icons/logo.svg";
import History from "../icons/history.svg";
import Catchup from "../icons/catch_up.svg";
import Browse from "../icons/browse.svg";
import { Link } from "react-router-dom";
import "./Nav.scss";
import { AuthContext } from "../../context/AuthContext";
import { DataContext } from "../../context/DataContext";
import Profile from "./Profile";
import Button from "@screencloud/screencloud-ui-components/.dist/components/button";

interface NavProps {
  children?: ReactNode;
  title: string;
  subtitle: string;
  currentLocation: string;
}

const links = [
  {
    url: "/",
    image: Catchup,
    alt: "Catchup",
    text: "Catch up",
  },
  {
    url: "/browse",
    image: Browse,
    alt: "Browse",
    text: "Browse",
  },
  {
    url: "/history",
    image: History,
    alt: "History",
    text: "History",
  },
];

const Nav = (props: NavProps): ReactElement<NavProps> => {
  const { children, title, subtitle, currentLocation } = props;
  const { logOut } = useContext(AuthContext);
  const { currentUser } = useContext(DataContext);

  return (
    <div className="nav-container">
      <div className="nav">
        <a href="/">
          <img src={Logo} id="logo" alt="Logo" />
        </a>

        <div className="nav-links">
          {links.map((link) => {
            return (
              <span key={link.url}>
                <div className={currentLocation === link.url ? "active" : ""}>
                  <Link to={link.url}>
                    <img src={link.image} alt={link.alt} />
                    <span>{link.text}</span>
                  </Link>
                </div>
              </span>
            );
          })}
        </div>
      </div>
      {(title || subtitle) && (
        <div className="header">
          <div className="title">{title}</div>
          <div className="subtitle">{subtitle}</div>
        </div>
      )}
      <div className="content">{children}</div>
      <div className="feedback">
        <Link to="/getting-started">
          <span>Getting started & feedback</span>
        </Link>
      </div>
      <div className="profile-container">
        {!!currentUser && <Profile {...currentUser} onLogOut={logOut} />}
        {!currentUser && (
          <div className="logout-button">
            <Button primary onClick={logOut}>
              Log out
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Nav;
