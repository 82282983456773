import React, { ReactElement, useContext, useEffect, useState } from "react";
import Loader from "@screencloud/screencloud-ui-components/.dist/components/loader";
import { AuthContext } from "../../context/AuthContext";
import { SessionInfo, WorkspaceProvider } from "../../types";
import WorkspacePicker from "../../components/shared/WorkspacePicker";
import { Redirect } from "react-router-dom";

const AuthCallback = (): ReactElement<{}> => {
  const { isLoggedIn, setSessionInfo, logOut } = useContext(AuthContext);
  const [appRedirectUrl, setAppRedirectUrl] = useState<string | null>(null);
  const [isError, setIsError] = useState(false);
  const [provider, setProvider] = useState<WorkspaceProvider>(
    (null as unknown) as WorkspaceProvider
  );
  useEffect(() => {
    const code = new URLSearchParams(window.location.search).get("code");
    const state = JSON.parse(
      new URLSearchParams(window.location.search).get("state") || "{}"
    );
    const { redirectUrl, provider } = state;
    setProvider(provider);
    setAppRedirectUrl(redirectUrl || "/");
    const authUrl = `${process.env.REACT_APP_API_BASE_URL}/auth/${provider}`;
    if (code) {
      const requestBody = {
        code,
        redirectUri: process.env.REACT_APP_REDIRECT_URI,
      };
      fetch(authUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res && res.token && res.refreshToken && res.expiresAt) {
            setSessionInfo(res as SessionInfo);
          }
        });
    } else {
      logOut();
      setIsError(true);
    }
  }, [setSessionInfo, logOut]);
  return isError ? (
    <Redirect to="/login" />
  ) : isLoggedIn && appRedirectUrl && provider ? (
    <WorkspacePicker redirectUrl={appRedirectUrl} provider={provider} />
  ) : (
    <Loader active size="massive" className="signal-loader" />
  );
};

export default AuthCallback;
