import React, { ReactElement, useContext, useEffect, useState } from "react";
import { DataContext } from "../../context/DataContext";
import { Workspace, WorkspaceProvider, SessionInfo } from "../../types";
import Button from "@screencloud/screencloud-ui-components/.dist/components/button";
import Loader from "@screencloud/screencloud-ui-components/.dist/components/loader";
import "./WorkspacePicker.scss";
import { AuthContext } from "../../context/AuthContext";
import { Redirect } from "react-router-dom";
import Slack from "../icons/slack-logo.svg";
import Teams from "../icons/teams-logo.svg";

interface WorkspacePickerProps {
  redirectUrl: string;
  provider: WorkspaceProvider;
}

const WorkspacePicker = (
  props: WorkspacePickerProps
): ReactElement<WorkspacePickerProps> => {
  const { redirectUrl, provider } = props;
  const { getWorkspaces } = useContext(DataContext);
  const { getUserToken, setSessionInfo, setWorkspace } = useContext(
    AuthContext
  );
  const [canRedirect, setCanRedirect] = useState(false);
  const [workspaces, setWorkspaces] = useState([] as Workspace[]);
  const [selectedWorkspace, setSelectedWorkspace] = useState(
    (null as unknown) as Workspace
  );

  useEffect(() => {
    getWorkspaces().then((workspaces) => {
      setWorkspaces(workspaces);
      if (workspaces.length === 1) {
        setCanRedirect(true);
        setWorkspace(workspaces[0]);
      }
    });
  }, [getWorkspaces, setWorkspace]);

  useEffect(() => {
    if (selectedWorkspace) {
      const switchWorkspaceUrl = `${process.env.REACT_APP_API_BASE_URL}/session/switch`;
      const requestBody = {
        id: selectedWorkspace.id,
      };
      fetch(switchWorkspaceUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getUserToken()}`,
        },
        body: JSON.stringify(requestBody),
      })
        .then((res) => res.json())
        .then((res) => {
          setSessionInfo(res as SessionInfo);
          setWorkspace(selectedWorkspace);
          setCanRedirect(true);
        });
    }
  }, [selectedWorkspace, getUserToken, setSessionInfo, setWorkspace]);

  return canRedirect ? (
    <Redirect to={redirectUrl} />
  ) : !!workspaces.length ? (
    <div className="teams-workspace-picker">
      <h1>Almost there!</h1>
      <h2>Please select your workspace</h2>
      <div className="workspaces">
        {workspaces.map((workspace) => {
          return (
            <div key={workspace.id} className="workspace">
              <div className="detail">
                <img
                  className={workspace.provider}
                  src={
                    workspace.provider === WorkspaceProvider.Slack
                      ? Slack
                      : Teams
                  }
                  alt={provider}
                />
                {workspace.name}
              </div>
              <Button primary onClick={() => setSelectedWorkspace(workspace)}>
                Select
              </Button>
            </div>
          );
        })}
      </div>
    </div>
  ) : (
    <Loader active size="massive" className="signal-loader" />
  );
};

export default WorkspacePicker;
