import { MessageCategory, categoryMap } from "../../../types";

export const getReadMessagesQuery = (
  category: MessageCategory,
  limit: number,
  offset: number
) => `{
      allReadMessages(filter: ${categoryMap[category]}, limit: ${limit}, offset: ${offset}) {
        id
        title
        text
        permalink
        url
        author {
          name
          profilePictureUrl
        }
        creator {
          id
          name
        }
        channel {
          id
          name
          description
          topic
        }
        type
        provider
        readAt
        createdAt
      }
    }`;
