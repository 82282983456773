import React, { ReactElement } from "react";
import ReactHtmlParser from "react-html-parser";
import UserProfile from "./UserProfile";

interface PrettifyProps {
  text: string;
}

const convertToJsx = (text: string) => {
  return ReactHtmlParser(text, {
    transform: (node) => {
      if (!!node.attribs && !!node.attribs["data-user-id"]) {
        const userId = node.attribs["data-user-id"];
        return (
          <UserProfile
            key={userId}
            userId={userId}
            name={node.children![0].data}
          />
        );
      }
    },
  });
};

const Prettify = (props: PrettifyProps): ReactElement<PrettifyProps> => {
  return <div className="prettified-text">{convertToJsx(props.text)}</div>;
};

export default Prettify;
