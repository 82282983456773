import React from "react";
import ReactDOM from "react-dom";
import "whatwg-fetch"; // required until cypress supports fetch API. https://github.com/cypress-io/cypress/issues/95
import "normalize.css"; // Import before any custom CSS. Smoothes out browser differences.
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Catchup from "./containers/Catchup/Catchup";
import Login from "./containers/Login/Login";
import { AuthProvider } from "./context/AuthContext";
import PrivateRoute from "./PrivateRoute";
import * as serviceWorker from "./serviceWorker";
import "./index.css";
import AuthCallback from "./containers/AuthCallback/AuthCallback";
import Browse from "./containers/Browse/Browse";
import Manage from "./containers/Manage/Manage";
import History from "./containers/History/History";
import { DataProvider } from "./context/DataContext";
import SignaledMessage from "./containers/SignaledMessage/SignaledMessage";
import PageNotFound from "./components/shared/PageNotFound";
import GettingStarted from "./containers/GettingStarted/GettingStarted";

ReactDOM.render(
  <BrowserRouter>
    <AuthProvider>
      <DataProvider>
        <Switch>
          <PrivateRoute exact path="/" component={Catchup} />
          <PrivateRoute exact path="/browse" component={Browse} />
          <PrivateRoute exact path="/manage" component={Manage} />
          <PrivateRoute exact path="/history" component={History} />
          <PrivateRoute
            exact
            path="/getting-started"
            component={GettingStarted}
          />
          <PrivateRoute path="/messages/:id" component={SignaledMessage} />
          <Route path="/login" component={Login} />
          <Route path="/callback" component={AuthCallback} />
          <Route path="*" component={PageNotFound} />
        </Switch>
      </DataProvider>
    </AuthProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
