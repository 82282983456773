import React, { ReactElement, useState, useEffect } from "react";
import { Card, Icon } from "semantic-ui-react";
import "./CategoryList.scss";
import ListItem from "./ListItem";
import { MessageCounts, MessageCategory } from "../../types";

interface CategoryListProps {
  messageCounts: MessageCounts | null;
  selectedCategory: MessageCategory | null;
  onSelect: (currentCategory: MessageCategory | null) => void;
  showCheckMark: boolean;
}
const CategoryList = (
  props: CategoryListProps
): ReactElement<CategoryListProps> => {
  const [
    currentCategory,
    setCurrentCategory,
  ] = useState<MessageCategory | null>(null);
  const { messageCounts, selectedCategory, onSelect, showCheckMark } = props;

  useEffect(() => {
    setCurrentCategory(selectedCategory);
  }, [selectedCategory]);

  useEffect(() => {
    onSelect(currentCategory);
  }, [currentCategory, onSelect]);

  return (
    <Card.Group>
      <ListItem
        title="Must See"
        subtitle="Company-wide announcements"
        selected={currentCategory === MessageCategory.MustSee}
        disabled={!messageCounts?.mustSee}
        onSelect={() => {
          setCurrentCategory(MessageCategory.MustSee);
        }}
      >
        {!!messageCounts && !!messageCounts.mustSee && (
          <div className="yellow-badge">{messageCounts.mustSee}</div>
        )}
        {!messageCounts ||
          (!messageCounts.mustSee && showCheckMark && (
            <Icon name="checkmark" color="green" />
          ))}
      </ListItem>
      <ListItem
        title="Following"
        subtitle="Updates from channels you follow"
        selected={currentCategory === MessageCategory.Subscription}
        disabled={!messageCounts?.following}
        onSelect={() => {
          setCurrentCategory(MessageCategory.Subscription);
        }}
      >
        {!!messageCounts && !!messageCounts.following && (
          <div className="yellow-badge">{messageCounts.following}</div>
        )}
        {!messageCounts ||
          (!messageCounts.following && showCheckMark && (
            <Icon name="checkmark" color="green" />
          ))}
      </ListItem>
      <ListItem
        title="Saved"
        subtitle="Your personal queue"
        selected={currentCategory === MessageCategory.Personal}
        disabled={!messageCounts?.saved}
        onSelect={() => {
          setCurrentCategory(MessageCategory.Personal);
        }}
      >
        {!!messageCounts && !!messageCounts.saved && (
          <div className="yellow-badge">{messageCounts.saved}</div>
        )}
        {!messageCounts ||
          (!messageCounts.saved && showCheckMark && (
            <Icon name="checkmark" color="green" />
          ))}
      </ListItem>
    </Card.Group>
  );
};

export default CategoryList;
