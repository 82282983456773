import React, { ReactElement, useState, useEffect, ReactNode } from "react";
import { Message, MessageType, VideoMessage } from "../../types";
import TextDetail from "../message-types/TextDetail";
import VideoDetail from "../message-types/VideoDetail";
import PhotoDetail from "../message-types/PhotoDetail";
import MessageHeader from "./MessageHeader";
import "./MessageDetail.scss";
import useEventListener from "../../hooks/useEventListener";
import GoogleSlidesDetail from "../message-types/GoogleSlidesDetail";

interface MessageDetailProps {
  message: Message | null;
  className?: string;
  onMessageRemoved?: (message: Message) => void;
  onSpaceBarPressed?: () => void;
  messageActions?: ReactNode;
}

const getMessageView = (message: Message): ReactNode => {
  switch (message.type) {
    case MessageType.Text:
      return <TextDetail message={message} />;
    case MessageType.Link:
      return <TextDetail message={message} />;
    case MessageType.Photo:
      return <PhotoDetail message={message} />;
    case MessageType.Video:
      return <VideoDetail message={message as VideoMessage} />;
    case MessageType.GoogleSlides:
      return <GoogleSlidesDetail message={message} />;
    default:
      return <></>;
  }
};
const MessageDetail = (
  props: MessageDetailProps
): ReactElement<MessageDetailProps> => {
  const {
    message,
    messageActions,
    className,
    onMessageRemoved,
    onSpaceBarPressed,
  } = props;
  const [animationClass, setAnimationClass] = useState("");

  useEffect(() => {
    setAnimationClass("");
  }, [message]);

  const onKeyUp = (event: KeyboardEvent): void => {
    event.stopImmediatePropagation();
    switch (event.key) {
      case " ":
        if (onSpaceBarPressed) {
          onSpaceBarPressed();
        }
        break;
      default:
        return;
    }
  };

  useEventListener("keyup", onKeyUp);

  const removeMessage = (): void => {
    if (onMessageRemoved && message) {
      onMessageRemoved(message);
    }
  };

  if (!message) {
    return <></>;
  }
  return (
    <>
      <div
        className={`message-detail ${
          className ? className : ""
        } ${animationClass}`}
        style={{ whiteSpace: "pre-wrap" }}
      >
        <MessageHeader
          message={message}
          messageActions={messageActions}
          onRemoveMessage={onMessageRemoved ? removeMessage : undefined}
        />
        {getMessageView(message)}
      </div>
    </>
  );
};

export default MessageDetail;
