import React, { ReactElement } from "react";
import Nav from "../../components/shared/Nav";
import TopBar from "../../components/shared/TopBar";
import Button from "@screencloud/screencloud-ui-components/.dist/components/button";
import "./GettingStarted.scss";

const GettingStarted = (): ReactElement<{}> => {
  return (
    <div className="getting-started-container">
      <div className="left-panel">
        <Nav
          currentLocation="/getting-started"
          title="Getting started"
          subtitle="Useful information and links"
        />
      </div>
      <div className="right-panel">
        <TopBar title="Getting Started" subtitle="">
          <a
            href={process.env.REACT_APP_FEEDBACK_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button outline white>
              Give us feedback
            </Button>
          </a>
        </TopBar>
        <div className="help-text">
          <div className="section hero">
            <h1>About Signal</h1>
            <p>
              Signal collects what’s important from Slack and creates a
              personalized queue to help you catch up. Only the updates that
              matter to you and your role, minus all the noise.{" "}
            </p>
            <p>
              Not all messages are created equal. Important updates get lost in
              cat memes. Meeting summaries sit at the end of (long) threads. How
              do you pick what to watch or read, without it taking all day?
            </p>
            <p>
              {" "}
              That’s where Signal fits in. Signal is an R&D project by
              ScreenCloud, looking at ways of reducing the noise and anxiety
              around work communication.
            </p>
          </div>
          <hr />
          <div className="section">
            <h1>Getting Started</h1>
            <p>
              Follow the video below to learn how to get setup and start
              Signalling messages from within&nbsp;Slack.
            </p>
            <iframe
              title="Getting Started Video"
              src="https://www.loom.com/embed/1b3cea8a4fd748439f5c05a1eb4c8edf"
              allowFullScreen
            ></iframe>
          </div>
          <div className="section">
            <h1>Feedback</h1>
            <p>
              We would love to hear any feedback you have. Signal is still in a
              Beta phase so we’re looking for feedback of how we can continue to
              improve it.{" "}
            </p>
            <p>
              The early version of Signal is compatible with Slack but we’re
              also working on an integration for Microsoft Teams and hope to be
              able to share this with you very soon.
            </p>
            <a
              href={process.env.REACT_APP_FEEDBACK_URL}
              target="_blank"
              rel="noopener noreferrer"
              className="feedback-btn"
            >
              <Button outline white>
                Give us feedback
              </Button>
            </a>
          </div>
          <div className="section">
            <h1>Data</h1>
            <p>
              Just a note on how we handle your data. When a message is
              signalled we store a copy of the text content of the message and
              some additional metadata including the channel and author. Follow
              this link for more{" "}
              <a
                href="https://docs.google.com/document/d/1188ZhybDnnxg6eJ4qtzXnax_6ZL66KxndJTOA7lqMrk/edit"
                target="_blank"
                rel="noopener noreferrer"
              >
                detailed information
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GettingStarted;
