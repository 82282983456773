export const getMessageQuery = (id: string) => `{
      getMessage(id: "${id}") {
        id
        title
        text
        permalink
        url
        author {
          name
          profilePictureUrl
        }
        creator {
          id
          name
        }
        channel {
          id
          name
          description
          topic
        }
        type
        provider
        readAt
        createdAt
      }
    }`;
